// @ts-expect-error: missing type declaration 这里直接用bridge而不是用封装好的 主要是封装好的bridge定义在新ui里面，跨新老ui引用担心出现某些问题
import BridgeV2 from '@akulaku/web-bridge'

/**
 * 【OP线上device_type传参错误问题修复】
 * @see https://www.tapd.cn/62870105/prong/stories/view/1162870105001157740
 * @tips redux中的也有 main.deviceType 这个字段，但是对应的值不一致，以及不知道redux中的值的用处
 * @returns {number}
 */
export const getDeviceType = () => {
  if (window.location.href.includes('/pc/')) {
    return 7
  }
  if (BridgeV2.isAkulakuAndroid()) {
    return 4
  }
  if (BridgeV2.isAkulakuIOS()) {
    return 5
  }
  return 6
}
