export class Timer {
  constructor() {
    this.timerList = {}
  }

  register({ name, callback, endCallback, time, interval = 1000 }) {
    this.timerList[name] = {
      timer: null,
    }

    this.timerList[name]['remove'] = this.removeTimer(name)
    this.timerList[name]['run'] = this.runTimer(
      name,
      callback,
      endCallback,
      time,
      interval,
    )

    return this.timerList[name]
  }

  runTimer = (name, callback, endCallback, time, interval) => {
    const timeRemover = this.removeTimer(name)
    return () => {
      let count = time
      this.timerList[name].timer = setInterval(() => {
        count--
        if (count < 0) {
          timeRemover(name)
          count = 0
          endCallback && endCallback()
          return
        }
        callback && callback(count)
      }, interval)
    }
  }

  removeTimer = (name) => {
    const timerObj = this.timerList[name]
    return () => {
      clearInterval(timerObj.timer)
      timerObj.timer = null
    }
  }

  get(name) {
    return this.timerList[name]
  }

  static create(params) {
    if (!Timer.instance) {
      Timer.instance = new Timer(params)
      return Timer.instance
    } else {
      return Timer.instance
    }
  }

  static getInstance() {
    if (!Timer.instance) {
      console.warn(
        "Timer has not been created yet, please use Timer.create() before getting it's instance",
      )
      return void 0
    }

    return Timer.instance
  }
}
