import { helpers } from '@common'

const { storage, URL } = helpers

export default function (data) {
  try {
    if (data.app_id) {
      return {
        device_id: storage.get('deviceId'),
        adjust_id: data.ajid,
        google_adid: data.adid,
        lgt: data.location.longitude,
        lat: data.location.latitude,
        app_brand: data.app_brand,
        time_zone: data.location.time_zone,
        time_zone_offset: data.location.time_zone_offset,
        sdk_version: data.sdk_version,
        app_id: data.app_id,
        uuid: data.uuid,
        ...data.broswer,
        ...data.sys_feature,
        ...data.resolution,
        os: data.os,
        color_depth: data.color_depth,
        canvas: '',
        webgl: '',
        webgl_attribute: data.webgl_attribute,
        ipv6: data.ipv6,
        font_display: data.font_display,
        webgl_vendor_renderer: data.webgl_vendor_renderer,
        device_memory: data.device_memory,
        pixel_ration: data.pixel_ration,
        cpu_class: data.cpu_class,
        fonts: data.fonts,
        fonts_flash: data.fonts_flash,
        audio: data.audio,
        enumerate_devices: data.enumerate_devices,
        extend_field_json: {
          ethIp: data.ethIp,
        },
      }
    } else {
      return {}
    }
  } catch (err) {
    return {}
  }
}
